const config = {
  okta: {
    issuer: 'https://millom.okta.com/oauth2/default',
    clientId: '0oaesdwdnXwBaNEjL416',
    devMode: false
  },
  api: {
    url: 'https://mgmt.ddt.telenor.io'
  }
}

export default config
